export default {
  background: '#FFFFFF',
  tableBackground: '#ECF1FD',
  colors: {
    text: '#2D3436',
    tableHeaderText: '#636E72',
    tableIndex: '#636E72',
    blue: '#0C6CF2',
    hoverButton: 'rgba(0,0,0,0.1)',
    hoverRow: '#FFFFFF',
    divider: 'rgb(223, 227, 235)',
  },
};
