import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

  interface PrivacyPolicyProps {
    // ? home screen interface
  }

const PrivacyPolicy: React.FC<RouteComponentProps<PrivacyPolicyProps>> = () =>(

<div style={{width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
        <div style={{width: '60%', margin: '0 auto', textAlign: 'left', fontSize: '18px', lineHeight: '30px'}}>
            <h1>Privacy Policy</h1>
            <p>Your privacy is important to us. It is FlukApp LLC&rsquo;s policy to respect your privacy regarding any information we may collect from you across our website, <span><a href='https//skaiscan.ru' target='_blank'>https//skaiscan.ru</a></span>, and other sites we own and operate.
We only ask for personal information when we truly need it to provide a service to you. We collect it by fair and lawful means, with your knowledge and consent. We also let you know why we&rsquo;re collecting it and how it will be used.
We only retain collected information for as long as necessary to provide you with your requested service. What data we store, we&rsquo;ll protect within commercially acceptable means to prevent loss and theft, as well as unauthorized access, disclosure, copying, use or modification.
We don&rsquo;t share any personally identifying information publicly or with third-parties, except when required to by law.
Our website may link to external sites that are not operated by us. Please be aware that we have no control over the content and practices of these sites, and cannot accept responsibility or liability for their respective privacy policies.
You are free to refuse our request for your personal information, with the understanding that we may be unable to provide you with some of your desired services.
Your continued use of our website will be regarded as acceptance of our practices around privacy and personal information. If you have any questions about how we handle user data and personal information, feel free to contact us.
This policy is effective as of 9 March 2022.</p>
            <h1>Face Data Policy</h1>
            <p>
                <p> <b> What face data is our app collecting? </b> </p>
                We analyze users photos and find out the skin condition features of the face in photo for processing purpose. We do not collect face data. After the analyzing process ends all data will be deleted, no data will be saved.
                <p> <b> For what purposes are we collecting this information? </b> </p>
                In order to provide app functionality, the app needs to find out the skin condition features. The complete process is: (1) You transfer pictures to our server. (2) The server analyzes your photo and returns the result of facial data processing. (3) The application automatically synthesizes the facial data processing results and materials into the final effect. (4) This information is temporarily stored in Amazon S3 encrypted storage. After the synthesis process is completed, your pictures will be automatically deleted immediately.
                <p> <b> Will the data be shared with any third parties? Where will this information be stored? </b> </p>
                The data will not be shared with any third parties. The photos you uploaded will be deleted after the analyzing process ends.
            </p>
        </div>
    </div>
)

export default PrivacyPolicy;