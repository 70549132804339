import styled from 'styled-components';

export const Main = styled.div`
  width: 100%;
  height: 100%;
  flex-direction: column;
  position: relative;
`;

export const CenterContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => `${props.theme.background}`};
`;

export const Div = styled.div`
  position: absolute;
  top: 10%;
  max-width: 800px;
  max-height: 300px;
  padding: 0px 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const Slogan = styled.div`
  text-align: center;
  font-size: 48px;
  line-height: 48px;
  font-weight: bold;
  @media (max-width: 425px) {
    font-size: 32px;
    line-height: 32px;
  }
`;

export const StartButton = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  max-width: 343px;
  min-width: 320px;
  height: 56px;

  background: #ffb28a;
  border-radius: 16px;
  border-style: none;
  cursor: pointer;

  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
`;

export const BackgroundImage = styled.img`
  flex: 1;
  align-self: center;
`;

export const RelativeContainer = styled.div`
  width: 100%;
  height: 435px;
  background: #f09c70;
  position: relative;
  border-radius: 32px;

  @media (max-width: 1100px) and (min-width: 700px){
    height: 400px;
  }

  @media (max-width: 700px){
    height: 780px;
  }

  @media (max-width: 426px){
    height: 800px;
  }

  @media (max-width: 400px){
    height: 650px;
  }
  
  @media (max-width: 320px){
    height: 700px;
  }
`;

export const LowerContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 150px;
  background: #f09c70;
  border-radius: 32px 32px 32px 32px;
  align-items: center;
  display:flex;

  @media (max-width: 700px){
    flex-direction: column;
  }
`;

export const DeviceImage = styled.img`
  align-self: center;
  margin-top: 40px;
  border-radius: 32px;
  @media (max-width: 968px){
    width: 350px;
  }

  @media (max-width: 400px){
    width: 250px;
  }
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 32px;
  height: 100px;
  padding: 0 20px;
  color: #ffffff;
  margin-top : 120px;

  @media (max-width: 1100px) and (min-width: 968px){
    margin-top : 140px;
  }

  @media (max-width: 968px){
    font-size: 28px;
    line-height: 28px;
    margin-top : 160px;
  }

  @media (max-width: 700px){
    height: auto;
    font-size: 24px;
    margin-top : 60px;

  }

  @media (max-width: 485px){
    font-size: 24px;
  }
`;

export const Description = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 24px;
  color: #ffffff;
`;

export const LeftSection = styled.div`
  display: block;
  width: 50%;
  
  @media (max-width: 700px){
    width: 100%;
  }

  @media (max-width: 485px){
    width: 100%;
  }
`;

export const RightSection = styled.div`
  display: block;
  width: 50%;
  @media (max-width: 700px){
    width: 100%;
  }
  @media (max-width: 485px){
    width: 100%;
  }
`;