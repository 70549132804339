export default {
  background: '#252525',
  tableBackground: 'rgb(30,31,41)',
  colors: {
    text: '#ffffff',
    tableHeaderText: '#B2BEC3',
    tableIndex: '#636E72',
    blue: '#0C6CF2',
    hoverButton: 'rgba(0,0,0,0.3)',
    hoverRow: '#4c515cff',
    divider: 'rgb(223, 227, 235, 0.5)',
  },
};
