import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import Background from '../../assets/background.png';
import Left from '../../assets/Left.png';
import Right from '../../assets/Right.png';
import {
  BackgroundImage,
  CenterContainer,
  DeviceImage,
  Div,
  LeftSection,
  LowerContainer,
  Main,
  RelativeContainer,
  RightSection,
  Slogan,
  StartButton,
  Title,
} from './styles';

interface HomeScreenProps {
  // ? home screen interface
}

declare const window: Window &
    typeof globalThis & {
  fbq: any,
  gtag: any,
  ttq: any
}


const HomeScreen: React.FC<RouteComponentProps<HomeScreenProps>> = () => (
  <Main>
    <CenterContainer>
      <Div>
        <Slogan>
          Помощник в уходе за кожей на основе искусственного интеллекта
        </Slogan>
        <StartButton onClick={() => {window.fbq('track', 'Lead'); window.gtag('event', 'click', {'event_category': 'LEAD',}); window.ttq.track('Contact'); window.location.href = 'https://skaiscan.netlify.app'}}>
          Начать сканирование
        </StartButton>
      </Div>
      <BackgroundImage src={Background} alt='water' />
    </CenterContainer>
    <RelativeContainer>
      <LowerContainer>
        <LeftSection>
          <Title>
            Есть разные типы акне. От типа зависит оптимальное средство для коррекции
          </Title>
          <DeviceImage src={Left} alt='water' />
        </LeftSection>
        <RightSection>
          <Title>
            Просто отсканируйте лицо и получите рекомендации
          </Title>
          <DeviceImage src={Right} alt='water' />
        </RightSection>
      </LowerContainer>
    </RelativeContainer>
  </Main>
);

export default HomeScreen;
