import styled from 'styled-components';

export const AppContainer = styled.div`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background-color: ${(props) => `${props.theme.background}`};
`;

export const HeaderApp = styled.div`
  width: 100%;
  display: flex;
  background-color: ${(props) => `${props.theme.background}`};
  padding: 30px 60px;
  align-items: flex-start;
`;

export const HeaderContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Footer = styled.div`
  display: flex;
  width: 100vw;
  height: 88px;

  background-color: ${(props) => `${props.theme.background}`};
  padding: 35px 0;

  flex-direction: row;
  justify-content: space-around;

  & :nth-child(2) {
    display:none;
  }

  @media (max-width: 426px){
    flex-direction:column;
    justify-content: center;
    padding: 0px 0 35px;
    height: 170px;
    & :nth-child(2) {
      display:block;
    }
  }
`;

export const FooterDescription = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 24px;

  text-align: right;
  text-decoration-line: underline;

  @media (max-width: 426px){
    text-align: center;
    padding-top: 24px;
    font-weight: 500
  }
`;

export const ThemeSwitchContainer = styled.div`
  height: 24px;

  .icon {
    fill: white !important;
    transition: all 400ms ease-in-out;
  }
  .light {
    &:hover {
      fill: #7f7f7f !important;
      cursor: pointer;
    }
  }

  .dark {
    &:hover {
      fill: #ff9248 !important;
      cursor: pointer;
    }
  }
`;
