import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

  interface TechDescProps {
    // ? home screen interface
  }

const TechDesc: React.FC<RouteComponentProps<TechDescProps>> = () =>(

<div style={{width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
        <div style={{width: '60%', margin: '0 auto', textAlign: 'left', fontSize: '18px', lineHeight: '30px'}}>
            <h1>Segmentation and Skin Type Detection for Dermatology Applications</h1>
            <h2>Introduction</h2>
            <p> This whitepaper describes our advanced medical app, which uses cutting-edge neural network models for facial segmentation and skin type detection. Our app employs state-of-the-art machine learning algorithms to assist users in identifying different types of acne and determining their skin type.
                Technical Details:
                Our app neural network models are based on the FPN VGG16 and ResNet50 architectures, which are powerful tools for image classification and segmentation. The FPN VGG16 architecture utilizes a feature pyramid network (FPN) to extract features at multiple scales, while the ResNet50 architecture employs skip connections to enable the training of very deep networks.
                Our FPN VGG16 model is designed to identify and segment different acne types, including whiteheads, blackheads, papules, and pustules, with an impressive F1 score of 85% for IOU. The ResNet50 architecture is used for skin type detection, achieving an accuracy of 90%. Our models were trained on a large dataset of facial images that have been marked by a PhD in dermatology, ensuring the accuracy and relevance of the results.</p>
            <h2>Validation</h2>
            <p>
                Our neural network models have been extensively validated and tested to ensure their accuracy and robustness. Our dataset was marked by a PhD in dermatology, and our models achieved high accuracy and F1 scores in both facial segmentation and skin type detection. We have also conducted extensive testing to validate the performance of our models, and the results have been consistently impressive.
            </p>
            <h2>Limitations and Disclaimer:</h2>
            <p>
                Our app is not intended to provide medical diagnosis or replace medical consultation. The results provided by our app are for informational purposes only and should not be used as a substitute for professional medical advice. We recommend that users seek medical attention if they have any concerns about their skin or health.
            </p>
        </div>
    </div>
)

export default TechDesc;