import React, { useState } from 'react';
import {BrowserRouter as Router,  Route, Switch} from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import Logo from './assets/HeaderLogo.svg';
import ThemeSwitch from './components/ThemeSwitch';
import HomeScreen from './screens/HomeScreen';
import PrivacyPolicy from './screens/PrivacyPolicy';
import TechDesc from './screens/TechDesc';
import {
  AppContainer,
  Footer,
  FooterDescription,
  HeaderApp,
  HeaderContent,
  ThemeSwitchContainer,
} from './styles';
import darkTheme from './themes/dark';
import lightTheme from './themes/light';

function Skaiscan() {
  const [dark, setDark] = useState<boolean>(false);

  return (
  
    <ThemeProvider theme={dark ? darkTheme : lightTheme}>
      <div>
        <AppContainer>
          <HeaderApp>
            <HeaderContent>
              <a href='/'><img src={Logo} alt='skaiscan' /></a>
              <ThemeSwitchContainer
                data-testid='theme-switch'
                onClick={() => setDark(!dark)}
                aria-hidden='true'>
                <ThemeSwitch dark={dark} />
              </ThemeSwitchContainer>
            </HeaderContent>
          </HeaderApp>
          <Router>
          <Switch>
            <Route path='/' exact component={HomeScreen} />
            <Route path='/PrivacyPolicy' component={PrivacyPolicy} />
            <Route path='/TechDesc' component={TechDesc} />
            <Route path='*' exact component={HomeScreen} />
          </Switch>
          </Router>
          
        </AppContainer>
        <Footer style={{color: dark ? 'orange' : 'black'}}>
        <FooterDescription>info@scaiscan.com</FooterDescription>
     <FooterDescription >Условия Использования</FooterDescription>
      <FooterDescription><a href='TechDesc' target='_blank' >Технология и ограничения</a></FooterDescription>
     <FooterDescription><a href='PrivacyPolicy' target='_blank' >Политика конфидециальности</a></FooterDescription>
        </Footer>
      </div>
    </ThemeProvider>  
  );
}

export default Skaiscan;

